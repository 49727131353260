import React from 'react'
import {Layout} from 'widgets'
import {OnlineAssistant, BackgroundHeart} from 'images'
import {Input, IntlTelInput, Button, SEO} from 'components'
import {IntlTelCountry} from 'models'
import {useDispatch} from 'react-redux'
import {navigate} from 'gatsby'
type Form = {
    name: string,
    lastname: string,
    email: string,
    country: null | IntlTelCountry
    country_phone: null | string,
}
type ValidationError = {
    name?: boolean,
    lastname?: boolean,
    email?: boolean,
    country?: boolean,
    country_phone?: boolean,
}
export default () => {
    const dispatch = useDispatch()
    const IFormState: Form = {
        name: '',
        lastname: '',
        email: '',
        country: null,
        country_phone: '',
    }
    const [form, setForm] = React.useState<Form>(IFormState)
    const change = (key: keyof Form, value: string) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    
    const defaultIntlClass = 'w-100 intl-tel-input input-purple basic-border form-control'
    const invalidIntlClass = defaultIntlClass  +' invalid-feedback '
    const [intlClassName, setIntlClassName] = React.useState<string>(defaultIntlClass)
    const [validationError, setValidationError] = React.useState<ValidationError>({})
    const onChangeIntl = (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => {
        let _form: Form = {
            ... form,
            country,
            country_phone
        }
        setForm(_form)
        if(country && country_phone){
            setIntlClassName(defaultIntlClass)
        }else{
            setIntlClassName(invalidIntlClass)
        }
    }
    
    const onSubmit = async () => {
        dispatch({
            type: 'SET_QUOTE_REQUEST',
            payload: form
        })
        navigate('/segundo-paso')
    }
    const isDisabled = (): boolean => {
        let disabled = !form.country || !form.country_phone || !form.email || !form.lastname || !form.name
        let _validationError = {}
        for (const key in form) {
            //@ts-ignore
            if(!form[key]){
                //@ts-ignore
                _validationError[key] = true
            }
        }
        const haveSymbol = form.email.includes('@')
        if((Object.values(_validationError).length > 0) || (form.email && !disabled && !haveSymbol)){
            disabled = true
        }
        return disabled
    }
    const onBlurField = (key: keyof ValidationError) => {
        const value = form[key]
        let _validationError = {... validationError}
        if((key === 'email' && !form.email.includes('@')) || (!value && !validationError[key])){
            _validationError[key] = true
        }else{
            delete _validationError[key]
        }
        setValidationError(_validationError)
    }

    const onFocusField = (key: keyof ValidationError) => {
        let _validationError = {... validationError}
        delete _validationError[key]
        setValidationError(_validationError)
    }
    return(
        <Layout>
            <SEO
                title="Cotiza tu seguro de viajes en pocos pasos con Compara Assist."
                description="Somos la forma segura y sencilla para consultar las distintas opciones de seguros de viajes y de salud."
                keywords="cotizar seguro de viajes, cotizar seguro, ofertas de seguros de viajes, seguro de viaje nacional, seguro de viajes internacional, viajar a europa, viajar a estados unidos, seguro de viaje estados unidos, seguro de viaje europa, viajar a asia, seguro de viajes asia"
            />
            <div
                className="online-assistant"
                style={{
                    backgroundImage: `url(${BackgroundHeart})`
                }}
            >
                <div className="w-100 text-center container">
                    <img
                        src={OnlineAssistant}
                        alt="Compara Assist - Asistente virtual"
                        className="bordered assistant"
                    />
                    <h2 className="text-purple">
                        Hola! Busquemos el SEGURO DE VIAJE ideal en un solo lugar. Iniciemos...
                    </h2>
                    <div id="form">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input
                                name="name"
                                className="input-purple basic-border"
                                placeholder="Tu nombre aqui"
                                required
                                hasError={validationError.name}
                                onBlur={() => onBlurField('name')}
                                onFocus={() => onFocusField('name')}
                                onChange={(value: string) => change('name', value)}
                                value={form.name}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                className="input-purple basic-border"
                                name="lastname"
                                placeholder="Tu apellido aqui"
                                onChange={(value: string) => change('lastname', value)}
                                value={form.lastname}
                                required
                                hasError={validationError.lastname}
                                onBlur={() => onBlurField('lastname')}
                                onFocus={() => onFocusField('lastname')}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                className="input-purple basic-border"
                                name="email"
                                placeholder="Tu email aqui"
                                type="email"
                                onChange={(value: string) => change('email', value)}
                                value={form.email}
                                required
                                hasError={validationError.email}
                                onBlur={() => onBlurField('email')}
                                onFocus={() => onFocusField('email')}
                            />
                        </div>
                        <div className="col-12">
                            <div className="w-100">
                                <IntlTelInput
                                    onChange={onChangeIntl}
                                    placeholder="WhatsApp"
                                    className={intlClassName}
                                />
                            </div>
                        </div>
                        <div className="container">
                            <div className="button-container">
                                <Button
                                    disabled={isDisabled()}
                                    onClick={() => onSubmit()}
                                    label="Cotizar"
                                    className="success w-100 text-white btn-pill"
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}